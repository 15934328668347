import React from 'react'
import './TxForm.css';
import './DepositStakeTxForm.css';
import _ from 'lodash'
import { connect } from 'react-redux'
import Pando from '../../services/Pando'
import TokenTypes from "../../constants/TokenTypes";
import FormInputContainer from '../FormInputContainer'
import GradientButton from '../buttons/GradientButton';
import { numberWithCommas } from '../../utils/Utils'
import { BigNumber } from 'bignumber.js';
import { store } from "../../state";
import { showModal } from "../../state/actions/Modals";
import ModalTypes from "../../constants/ModalTypes";
import Pandojs from '../../libs/pandojs.esm';
import { getMinStakeAmount } from "../../Flags";
import API from '../../services/Api';
import Wallet from '../../services/Wallet';
import { withTranslation } from 'react-i18next';
import config from '../../Config';
import MDSpinner from "react-md-spinner";

const TRANSACTION_FEE = config.currentFee;


// This component is used for the functionality in the Deposit stake button under the stake menu
class DepositStakeTxForm extends React.Component {
    constructor(props) {
        super(props);
        let holder = ''
        let isDisabled = Wallet.getStakeAddress() ? true : false;


        this.state = {
            tokenType: (props.defaultTokenType || TokenTypes.PANDO),
            holder: holder,
            amount: '',
            sequence: '',
            transactionFee: Pando.getTransactionFee(),
            invalidHolder: false,
            insufficientFunds: false,
            invalidAmount: false,
            invalidDecimalPlaces: false,
            RametronAmmountError: false,
            invalidRametronError: false,
            remetronStek: '',
            invalidAmmount: true,
            walletAmount: 0,
            metaZetaInvalid: false,
            tempArray: [],
            multipleHolder: false,
            isdisabled: isDisabled,
            publicPoolList: [],
            spinner: false

        };

        this.handleChange = this.handleChange.bind(this);
        this.showlist = this.showlist.bind(this)
        this.handleEntireBalanceClick = this.handleEntireBalanceClick.bind(this);
    }

    getBalanceOfTokenType(tokenType) {
       
        if (this.props.purpose === 0) {
            if (Wallet.getStakeType().zytastake >= 10000000) {
                return 1000000;
            }
            return 10000000;

        } else if (this.props.purpose === 1) {
            if (Wallet.getStakeType().metastake >= 300000) {
                return 10000;
            }
            return 300000;

        }
        else if (this.props.purpose === 2) {
            if (Wallet.getStakeType().rtstake >= 90000) {
                return 1000;
            }
            return 90000;

        }
        else if (this.props.purpose === 3) {
            if (Wallet.getStakeType().rtstake >= 45000) {
                return 1000;
            }
            return 45000;
        }
        else if (this.props.purpose === 4) {
            if (Wallet.getStakeType().rtstake >= 22500) {
                return 1000;
            }
            return 22500;
        }

        else {
            if (Wallet.getStakeType().rtstake >= 1000) {
                return 1000;
            }
            return 1000
        }
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;

        if (name === 'tokenType') {
            //Reset user entered data to ensure they don't send the incorrect amount after changing currency
            let defaults = {
                holder: '',
                amount: '',
                sequence: '',
                transactionFee: TRANSACTION_FEE,
                invalidHolder: false,
                invalidDecimalPlaces: false,
                invalidAmount: false,
                insufficientFunds: false,
                RametronAmmountError: false
            };

            this.setState(Object.assign(defaults, { tokenType: value }));
        }
        else {
            if (name === "amount") {
                value = value.replace(/[^0-9.]/g, '');
            }

            this.setState({ [name]: value }, () => {
                this.validate();
            });
        }
    }

    showlist() {

    }

    handleDepositStakeClick = () => {

        store.dispatch(showModal({
            type: ModalTypes.DEPOSIT_STAKE_CONFIRMATION,
            props: {
                network: Pando.getChainID(),
                transaction: {
                    purpose: this.props.purpose,
                    tokenType: this.state.tokenType,
                    sequence: this.state.sequence,
                    from: this.props.walletAddress,
                    holder: this.state.holder,
                    amount: this.state.amount,
                    transactionFee: this.state.transactionFee
                }
            }
        }));

    };

    isValid() {
        return (
            this.state.holder.length > 0 &&
            this.state.amount.length > 0 &&
            this.state.invalidHolder === false &&
            this.state.insufficientFunds === false &&
            this.state.invalidDecimalPlaces === false &&
            this.state.invalidAmount === false &&
            this.state.invalidRametronError === false

        );
    }

    async calculateEntirePTXBalance() {
        let transactionFee = this.state.transactionFee;
        let balance = this.getBalanceOfTokenType(TokenTypes.PANDO);

        if (transactionFee) {
            let transactionFeeBN = new BigNumber(transactionFee);
            let ptxBalanceBN = new BigNumber(balance);
            let amountToSendBN = ptxBalanceBN.minus(transactionFeeBN);

            this.setState({
                amount: amountToSendBN.toString()
            });
        }
    }

    async handleEntireBalanceClick() {
        if (this.state.tokenType === TokenTypes.PANDO) {
            let balance = this.getBalanceOfTokenType(TokenTypes.PANDO);

            this.setState({
                amount: balance
            });
        }
    }

    validate() {
        if (this.state.holder.length > 0) {
            this.validateHolder();
        }

        if (this.state.amount.length > 0) {
            this.validateAmount();
        }
    }

    async validateHolder() {
        let tempholder = this.state.holder
        const { purpose } = this.props;
        let isValid = false;

        if (purpose === Pandojs.StakePurposes.StakeForValidator) {
            isValid = Pando.isAddress(this.state.holder);
        }
        else if (purpose === Pandojs.StakePurposes.StakeForGuardian) {
            isValid = Pando.isHolderSummary(this.state.holder);
        }
        else {
            isValid = Pando.isHolderSummary(this.state.holder);
            // isValid = false
            // if (tempisValid) {
            //     if (!tempholder.startsWith('0x')) {
            //         tempholder = '0x' + tempholder
            //     }
            //     let tempsummry = tempholder.substring(0, 42);
            //     let found = false;
            //     for (let i of this.state.tempArray) {
            //         if (i.holder.toLowerCase() === tempsummry.toLowerCase()) {
            //             found = true
            //         }
            //     }
            //     if (found) {
            //         isValid = true
            //     }
            //     else {
            //         isValid = false
            //     }

            // }
           

        }
        this.setState({ invalidHolder: (isValid === false) });

    }

    async validateAmount() {
        const { purpose } = this.props;
        let amountFloat = parseFloat(this.state.amount);
        let pandpBalance = this.getBalanceOfTokenType(TokenTypes.PANDO);
        let balance = null;

        if (this.state.tokenType === TokenTypes.PANDO) {
            balance = pandpBalance;
        }
        if (amountFloat < balance) {
            this.setState({
                insufficientFunds: true,
                invalidAmount: true,
                invalidDecimalPlaces: false
            });
        } else {
            this.setState({
                insufficientFunds: false,
                invalidAmount: false,
                invalidDecimalPlaces: false
            });
        }


        if (amountFloat > this.state.walletAmount) {
            this.setState({ metaZetaInvalid: true })
        } else {
            this.setState({ metaZetaInvalid: false })

        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.holder !== prevState.holder) {
            this.validateHolder();
        }

        if (this.state.amount !== prevState.amount || this.state.gasPrice !== prevState.gasPrice) {
            this.validateAmount();
        }
    }
    componentDidMount() {
        let address = Wallet.getWalletAddress();
        const { purpose } = this.props;
        this.setState({ spinner: true })

        if (purpose > 1) {
            if (Wallet.getStakeAddress()) {

                API.getWalletSummary(Wallet.getStakeAddress()).then((his) => {
                    if (his && his.data) {
                        let tarr = []
                        API.fetchStakes(address).then((data) => {
                            for (let i of data.body.sourceRecords) {
                                if (!i.withdrawn && i.type == 'rametronenterprisep') {
                                    let tempItem = { 'holder': i.holder }
                                    tarr.push(tempItem)

                                    break;
                                }
                            }
                            this.setState({ tempArray: tarr ,holder: his.data[0].nodesummery})
                        })

                    }
                })
            }

        }
        else {
            this.state.holder = '';
            this.state.isdisabled = false
        }

        API.getWalletBalance(address).then(res => {

            if (res.body && res.body.balance) {
                const newObj = Object.fromEntries(
                    Object.entries(res.body.balance).map(([k, v]) => [k.toLowerCase(), v])
                );
                let bal = Number(newObj.ptxwei / 1000000000000000000);


                this.setState({ walletAmount: bal });

                if (bal > 0) {
                    this.setState({ invalidAmmount: false })
                } else {
                    this.setState({ invalidAmmount: true })
                }

            }
            else {
                this.setState({ invalidAmmount: true })
            }
            if (!this.state.isdisabled && this.props.purpose > 1) {

                API.getAllPublicNodeaBasedOnType(purpose).then(res => {
                    // console.log(res.data)
                    let finaldata = []
                    for (let i of res.data) {
                        if (i.active) {
                            finaldata.push(i)
                        }
                    }
                    this.setState({ publicPoolList: finaldata });
                    this.setState({ spinner: false })
                });
            }
            else {
                this.setState({ spinner: false })
            }
        }).catch(() => {
            // API.getAllPublicNodeaBasedOnType(purpose).then(res => {
            //     this.setState({ publicPoolList: res.data });
            //     this.setState({ spinner: false })
            // });
        })


    }
    setNode(data) {
        this.setState({ holder: data.nodesummery })
    }

    preventDecimal(e) {
        if (e.keyCode == 190 || e.keyCode == 110) {
            e.preventDefault();
            return false;
        }
    }

    render() {
        const { purpose, t } = this.props;
        let spinner = this.state.spinner;
        let hasHolder = (this.state.holder !== null && this.state.holder !== '' && this.state.invalidHolder === false);

        let pandoTitle = `PTX (${this.getBalanceOfTokenType(TokenTypes.PANDO)} Minimum)`;
        let transactionFeeValueContent = (
            <React.Fragment>
                <span>{t(`TRANSACTION_FEE`)}</span>
            </React.Fragment>
        );
        let amountTitleContent = (
            <React.Fragment>
                <span>{t(`AMOUNT`)}</span>

            </React.Fragment>

        );

        let isValid = this.isValid();
        let toError = null;
        let amountError = null;

        if (this.state.invalidHolder) {
            if (purpose === Pandojs.StakePurposes.StakeForValidator) {
                toError = t(`INVALID_HOLDER_ADDRESS`);
            }
            else if (purpose === Pandojs.StakePurposes.StakeForGuardian) {
                toError = t(`INVALID_HOLDER_SUMMARY`);
            }
            else {

                if (this.state.multipleHolder) {
                    toError = t(`INVALID_HOLDER_SUMMARY`) + ' ( Note: can not deposit to multiple and already used holder summary for Rametron node type)';
                }
                else {
                    toError = t(`INVALID_HOLDER_SUMMARY`);
                }
            }
        }

        if (this.state.insufficientFunds) {
            amountError = `${t(`INVALID_AMOUNT_MUST_BE_AT_LEAST`)} ${this.getBalanceOfTokenType(TokenTypes.PANDO)
                } PTX`;

        }
        else if (this.state.invalidDecimalPlaces) {
            amountError = t(`INVALID_DENOMINATION`);
        }
        else if (this.state.RametronAmmountError) {
            amountError = t(`INSUFFICIENT_RAMETRON`);

        }
        else if (this.state.invalidAmount) {
            amountError = `${t(`INVALID_AMOUNT_MUST_BE_AT_LEAST`)} ${numberWithCommas(getMinStakeAmount(purpose))} PTX`;
        } else if (this.state.invalidRametronError) {
            amountError = `Invalid denomination, minimum top is ${this.getBalanceOfTokenType(TokenTypes.PANDO)} PTX`

        }

        let holderTitle = "";
        let holderPlaceholder = "";

        if (purpose === Pandojs.StakePurposes.StakeForValidator) {
            holderTitle = t(`ZYTATRON_NODE_HOLDER_(ADDRESS)`);
            holderPlaceholder = t(`ENTER_ZYTATRON_NODE_ADDRESS`);
        }
        else if (purpose === Pandojs.StakePurposes.StakeForGuardian) {
            holderTitle = t(`METATRON_NODE_HOLDER_(NODE_SUMMARY)`);
            holderPlaceholder = t(`ENTER_METATRON_NODE_SUMMARY`);

        } else {
            holderTitle = t(`RAMETRON_NODE_HOLDER(SUMMARY)`);
            holderPlaceholder = t(`ENTER_RAMETRON_NODE_SUMMARY`);

        }



        return (
            <div>
                {
                    spinner && <MDSpinner singleColor="#ffffff" className="StakesPage__detail-view-spinner" />

                }

                {
                    !spinner &&
                    <div className="TxForm">
                        <FormInputContainer title="Token">
                            <select className="BottomBorderInput" value={this.state.tokenType} onChange={this.handleChange}
                                name="tokenType">
                                <option value={TokenTypes.PANDO}>{pandoTitle}</option>
                            </select>
                        </FormInputContainer>
                        {(!this.state.isdisabled && purpose > 1) &&
                            <div className="dropdown customds">
                                <div className="dd-button dropdown-toggle dropdown" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-expanded="false">


                                    {t(`CHOOSE_PUBLIC_NODE`)}

                                    <img src="../img/logo/Icon awesome-angle-down.svg " alt="" className="imgClass" />
                                    <input type="checkbox" className="dd-input" id="test" />

                                </div>

                                <ul className="dd-menu dropdown-menu" for="dropdownMenuButton">
                                    {this.state.publicPoolList.length > 0 ?
                                        this.state.publicPoolList.map((val, index) =>
                                            <li key={index} onClick={() => this.setNode(val)} >{val.owner}</li>
                                        ) :
                                        <li>

                                            {t(`No_list_available`)}
                                        </li>
                                    }
                                </ul>

                            </div>

                        }
                        <FormInputContainer title={holderTitle}
                            error={toError}>
                            <input className="BottomBorderInput"
                                autoComplete="off"
                                name="holder"
                                placeholder={holderPlaceholder}
                                value={this.state.holder}
                                onChange={this.handleChange}
                                disabled={this.state.isdisabled}
                                onClick={this.showlist}
                            />
                        </FormInputContainer>


                        <FormInputContainer title={amountTitleContent}
                            error={amountError}>
                            <input className="BottomBorderInput" value={this.state.amount}
                                name="amount"
                                autoComplete="off"
                                placeholder="Enter amount to stake"
                                onChange={this.handleChange}
                                type="number"
                                min="1"
                                onKeyDown={this.preventDecimal}
                            />

                        </FormInputContainer>

                        {/* {this.state.insufficientFunds ? <span className="text-danger">{t(`USER_WALLET_MUST_HAVE_SUFFICIENT_AMOUNT_ALONG`)}</span> : null} */}
                        <GradientButton title={t(`DEPOSIT_STAKE`)} className="GradientButton"
                            disabled={isValid === false || this.state.invalidAmmount || this.state.amount < 1 || this.state.insufficientFunds || this.state.invalidRametronError || this.state.metaZetaInvalid}
                            onClick={this.handleDepositStakeClick} />

                        {this.state.invalidAmmount ?
                            <span className="text-danger thin">{t(`INSUFFICIENT_BALANCE`)}</span>
                            : ''

                        }
                        {this.state.metaZetaInvalid ?
                            <span className="text-danger thin">{t(`USER_WALLET_MUST_HAVE_SUFFICIENT_AMOUNT`)}</span>
                            : ''

                        }
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        balancesByType: state.wallet.balancesByType,
        walletAddress: state.wallet.address,
    };
};

export default withTranslation()(connect(mapStateToProps)(DepositStakeTxForm));
