import React from 'react'
import './ReceiveModal.css';
import Modal from '../components/Modal'
import Wallet from '../services/Wallet'
import GhostButton from '../components/buttons/GhostButton'
import { copyToClipboard } from "../utils/Utils";
import Alerts from '../services/Alerts'
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class ReceiveModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    handleCopyAddressClick = () => {
        let address = Wallet.getWalletAddress();
        copyToClipboard(address);
        Alerts.showSuccess(this.props.t(`YOUR_ADDRESS_HAS_BEEN_COPIED`));
    };

    render() {
        const { isLoading } = this.state;
        let { t } = this.props
        let address = Wallet.getWalletAddress();
        let qrCodeURL = `https://chart.googleapis.com/chart?chs=160x160&cht=qr&chl=${address}&choe=UTF-8&chld=L|0`;
      
        return (
            <Modal>
                <div className="ReceiveModal">
                    <div className="ReceiveModal__title">
                    <img src={'/img/home/reciev.svg'}/> <br/>
                        {t(`RECEIVE`)}
                    </div>
                    <div className="ReceiveModal__public-address-title thin">
                        {t(`MY_PUBLIC_ADDRESS`)}
                    </div>
                    <div className="ReceiveModal__public-address">
                       <p> {address} </p>
                        <div className="ReceiveModal__buttons">
                        <GhostButton 
                            iconUrl="/img/home/copy.svg"
                            onClick={this.handleCopyAddressClick}
                        />
                    </div>
                    </div>
                   

                    {/* <img src={qrCodeURL}
                        className="ReceiveModal__qr"
                    /> */}

                  
                </div>
            </Modal>
        )
    }
}
export default withTranslation()(ReceiveModal)
