import React from 'react'
import './TxConfirmationModal.css';
import './SendConfirmationModal.css';
import connect from "react-redux/es/connect/connect";
import Modal from '../components/Modal'
import GradientButton from "../components/buttons/GradientButton";
import Wallet, { WalletUnlockStrategy } from '../services/Wallet'
import { tokenTypeToTokenName } from "../constants/TokenTypes";
import { numberWithCommas } from "../utils/Utils";
import apiService from '../services/Api';
import { hideModal, hideModals } from "../state/actions/Modals";
import { store } from "../state";
import Alerts from '../services/Alerts';
import Router from '../services/Router';
import { withTranslation } from 'react-i18next';
import pando from '../services/Pando';
import config from '../Config';
import TemporaryState from '../services/TemporaryState'
import Web3 from 'web3';
import { BigNumber } from 'bignumber.js';
import pnc20 from '../pnc20';


const rpcURL = config.rpcUrl
const web3 = new Web3(rpcURL);
const pandoAbi = pnc20



class SendConfirmationModal extends React.Component {
    constructor() {
        super();

        this.state = {
            password: '',
            isloading: false,
            type: 'PTX'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSendClick = this.handleSendClick.bind(this);
        this.tokenTrasfer = this.tokenTrasfer.bind(this);
        // this.getGasPrice = this.getGasPrice.bind(this);
        // this.signTransaction = this.signTransaction.bind(this);
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    componentDidMount() {
        if (this.props.transaction.tokenType != 'PTX') {
            this.setState({ type: this.props.transaction.tokenType })
        }

    }


    handleSendClick() {
        let keyStoreData = null;
        let unlockStrategy = Wallet.getUnlockStrategy();
        let unLockKey = Wallet.getUnlockKey();
        this.setState({ isloading: true });
        let message;
        let { t } = this.props

        const body = {
            "tokenType": "PTX",
            "from": this.props.transaction.from,
            "to": this.props.transaction.to,
            "amount": Number(this.props.transaction.amount),
            "transactionFee": config.stakeTransactionFee,
        }
        try {

            if (WalletUnlockStrategy.KEYSTORE_FILE === unlockStrategy) {

                keyStoreData = Wallet.decryptFromKeystore(Wallet.getKeystore(), this.state.password);


            } else if (WalletUnlockStrategy.PRIVATE_KEY === unlockStrategy) {

                if (this.state.password != TemporaryState.getWalletData().sessionPassword) {
                    this.setState({ isloading: false });
                    message = t(`WRONG PASSWORD`);
                    Alerts.showError(message);
                    return
                }
                keyStoreData = Wallet.walletFromPrivateKey(unLockKey)
            } else if (WalletUnlockStrategy.MNEMONIC_PHRASE === unlockStrategy) {
                if (this.state.password != TemporaryState.getWalletData().sessionPassword) {
                    this.setState({ isloading: false });
                    message = t(`WRONG PASSWORD`);
                    Alerts.showError(message);
                    return
                }
                keyStoreData = Wallet.walletFromMnemonic(unLockKey)
            }
        }
        catch (e) {
            this.setState({ isloading: false });
            message = t(`WRONG PASSWORD`);
            Alerts.showError(message);
        }

        if (message !== 'Wrong password') {
            let { t } = this.props
            apiService.getSequence(body.from).then((seqres) => {
                if (seqres && seqres.type === 'account') {
                    let sequence = Number(seqres.body.sequence) + 1;

                    // call smart contract token transfer
                    if (this.props.transaction.tokenType != 'PTX') {
                        this.tokenTrasfer(sequence, keyStoreData.privateKey)
                        return;
                    }

                    // if ptx call here
                    const s = pando.unsignedSendTx(body, sequence);
                    pando.signTransaction(s, keyStoreData.privateKey).then((data) => {
                        apiService.sendTransaction(data).then((res) => {

                            if (res.data && res.data.result) {
                                let pendingTX = []
                                if (sessionStorage.getItem('pendingTX')) {
                                    pendingTX = JSON.parse(sessionStorage.getItem('pendingTX'))
                                }
                                res.data.result.type = "tx"
                                res.data.result.status = "pending"
                                pendingTX.push(res.data.result)
                                sessionStorage.setItem('pendingTX', JSON.stringify(pendingTX))
                                setTimeout(() => {

                                    store.dispatch(hideModals());
                                    this.setState({ isloading: false });
                                    Alerts.showSuccess('Transaction Broadcasted, will be reflected in some time!');
                                    Router.push('/wallet/dashboard')
                                    setTimeout(() => {
                                        document.getElementById("Refresh1").click();
                                    }, 2000)

                                }, 500)

                            }
                            else {

                                Alerts.showError(res.message);
                                this.setState({ isloading: false });
                                store.dispatch(hideModals());
                            }
                        })

                    })
                }
            })
        }

    } 




    async tokenTrasfer(seq, pvkey) {
        try {
            let from = this.props.transaction.from;
            let to = this.props.transaction.to
           
            let pandoContract =  await new web3.eth.Contract(pandoAbi.abi, this.props.transaction.tokenType);
           
            let decimalss = await pandoContract.methods.decimals().call();
            // console.log(decimalss)
        
          
       
            // //const oneGWEI = new BigNumber.from(10).pow(decimalss);
    
            // //let amount =   new BigNumber(this.props.transaction.amount).multipliedBy(ten18);

            // const decimals = decimalss;
            // const input = this.props.transaction.amount;
            // const amount = new BigNumber.from(input).mul(newBigNumber.from(10).pow(decimals));

            //const amount = web3.eth.utils.parseUnits(this.props.transaction.amount, decimalss)

            let pet = ((10**decimalss)*this.props.transaction.amount).toString()
            // let amount = this.bignumber(decimalss)
            let dataFunction = await pandoContract.methods.transfer(to, pet).encodeABI();
            const count = await web3.eth.getTransactionCount(from);

            const createTransaction = await web3.eth.accounts.signTransaction({
                "from": this.props.transaction.from,
                "nonce": web3.utils.toHex(count),
                "gas": web3.utils.toHex(100000),
                "to": this.props.transaction.tokenType,
                "data": dataFunction
            },
                pvkey
            );

            // Deploy transaction
            const createReceipt =  web3.eth.sendSignedTransaction(
                createTransaction.rawTransaction
            );
            // setTimeout(() => {

                store.dispatch(hideModals());
                this.setState({ isloading: false });
                Router.push('/wallet/dashboard')
                Alerts.showSuccess('Transaction Broadcasted, will be reflected in some time!');
                setTimeout(() => {
                    document.getElementById("Refresh1").click();
                }, 15000)

            // }, 100)
        }
        catch(err) {
            console.log(err)
            Alerts.showError('Transaction Failed! Please check input');
            this.setState({ isloading: false });
            store.dispatch(hideModals());
        }
    }

    bignumber(decimalss) {
        const ten18 = (new BigNumber(10)).pow(decimalss);
        let amount =  (new BigNumber(this.props.transaction.amount)).multipliedBy(ten18);
        return amount;
    }
    render() {
        let { tokenType, amount, to, transactionFee } = this.props.transaction;
        let isValid = Wallet.getWalletHardware() || this.state.password.length > 0;
        let isLoading = this.props.isCreatingTransaction;
        let { t } = this.props
        let currentDemo = config.currentFee



        let renderDataRow = (title, value) => {
            return (
                <div>
                    <div className="TxConfirmationModal__row">
                        <div className="TxConfirmationModal__row-title">
                            {title}
                        </div>
                        <div className="TxConfirmationModal__row-value">
                            {value}
                        </div>

                    </div>

                    {
                        this.state.type != 'PTX' ?

                            <div className="TxConfirmationModal__row">
                                <div className="TxConfirmationModal__row-title">
                                    Contract Address
                                </div>
                                <div className="TxConfirmationModal__row-value">
                                    {this.props.transaction.tokenType}
                                </div>

                            </div>

                            : ''}
                </div>
            );
        };
        let detailRows = null;

        detailRows = (
            <React.Fragment>
                {renderDataRow("From", this.props.walletAddress)}
                {/* { renderDataRow("Transaction Fee", currentDemo + " PTX")} */}
            </React.Fragment>
        );

        let passwordRow = null;

        if (!Wallet.getWalletHardware()) {
            passwordRow = (
                <div className="TxConfirmationModal__password-container">
                    <div className="TxConfirmationModal__password-title">{t(`ENTER_YOUR_WALLET_PASSWORD_TO_SIGN_THIS_TRANSACTION`)}</div>
                    <input className="ChoosePasswordCard__password-input"
                        placeholder={t(`ENTER_WALLET_PASSWORD`)}
                        name="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                    />
                </div>
            );
        }
        let pwdDisable;
        if (this.state.password.length === 0) {
            pwdDisable = true;
        } else {
            pwdDisable = false;

        }

        return (
            <Modal>
                <div className="TxConfirmationModal">
                    <div className="TxConfirmationModal__title">
                        {t(`CONFIRM_TRANSACTION`)}
                    </div>

                    <div className="TxConfirmationModal__amount-title">{t(`YOU_ARE_SENDING`)}</div>
                    <div className="TxConfirmationModal__amount">{numberWithCommas(amount)}</div>

                    {
                        this.state.type != 'PTX' ?
                            <div className="TxConfirmationModal__token-name"><b>{this.props.transaction.tokenSymbol}  (PNC-20)</b></div>
                            :
                            <div className="TxConfirmationModal__token-name"><b>{tokenTypeToTokenName(tokenType)}</b></div>
                    }

                    <div className="TxConfirmationModal__to-title">{t(`TO_RECIPIENT`)}</div>
                    <div className="TxConfirmationModal__to">{to}</div>

                    <div className="TxConfirmationModal__rows">
                        {detailRows}
                    </div>

                    {passwordRow}

                    <GradientButton className="GradientButton" title={t(`SEND`)}
                        disabled={this.state.isloading || pwdDisable}
                        onClick={this.handleSendClick}
                        loading={this.state.isloading}
                    />
                    <div className="TxConfirmationModal__to-title"> {t(`TRANSACTION_FEE`)}</div>
                    <div className="TxConfirmationModal__to"> {currentDemo} PTX</div>


                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        walletAddress: state.wallet.address,
        isCreatingTransaction: state.transactions.isCreatingTransaction,
    };
};

export default withTranslation()(connect(mapStateToProps)(SendConfirmationModal));
