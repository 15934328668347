import React from "react";
import './NftPage.css';

function NFTPage(){
    window.scrollTo(0, 0);
    return(
        <div>
             <section id="tokens-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                             <div className="tok-flex">
                                <h6>Tokens</h6>
                                <img data-bs-toggle="modal" data-bs-target="#exampleModal" src="/img/icons/add.png"></img>
                             </div>
                            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-title">
                                            <img src="/img/icons/token.png"></img>
                                            <h5>Track NFT</h5>
                                        </div>
                                        <i className="fas fa-times close-btn" data-bs-dismiss="modal" aria-label="Close"></i>
                                        <div className="modal-body">
                                            <div className="form-box">
                                                <div class="row custom-top">
                                                    <div class="col-lg-12">
                                                        <div class="input-field">
                                                            <span>Contract Address</span>
                                                            <input  type="text" name="text-397" placeholder="Enter contract address"></input>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="input-field">
                                                            <span>Token Id</span>
                                                            <input  placeholder="Enter Token Id" value="" type="text" name="text-398"></input>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <button>Track</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="token-box">
                                <div className="lefty-flex">
                                    <img src="/img/icons/toekn1.png"></img>
                                    <div className="des">
                                        <h6>Cool_fox</h6>
                                        <p>Panda_group</p>
                                    </div>
                                </div>
                                <div className="righty">
                                    <h5>200 PTX</h5>
                                    <h4><b>$ 100.005</b></h4>
                                </div>
                            </div>
                            <div className="token-box">
                                <div className="lefty-flex">
                                    <img src="/img/icons/toekn1.png"></img>
                                    <div className="des">
                                        <h6>Cool_fox</h6>
                                        <p>Panda_group</p>
                                    </div>
                                </div>
                                <div className="righty">
                                    <h5>200 PTX</h5>
                                    <h4><b>$ 100.005</b></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default NFTPage;