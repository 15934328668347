import React from "react";
import "./HomePage.css";
import { Link } from "react-router-dom";


class Homepage extends React.Component {
  constructor() {
    super();
    this.state = {
      latestPrice: ""
    }
  }
  componentDidMount() {

    fetch("https://prodexplorer.pandoproject.org/api/lbank/price").then(
      (resp) => {
        resp.json().then((res) => {
          let resData = res.lBankPrice[0].latestPrice;
          this.setState({ latestPrice: resData });
          // console.log(this.state.latestPrice)
        });
      }
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <section id="home-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-des">
                  <h2>
                    Your passport to a digital <br></br> financial revolution
                  </h2>
                  <p>
                    Welcome to Pando Wallet, your trusted platform for seamless
                    storage, transactions, and growth of your valuable Pando
                    Tokens
                  </p>
                  <div className="btn-des">
                    <Link to="/create" className="btn1">
                      Create Wallet
                    </Link>




                    <Link to="/unlock/keystore-file" className="btn124">
                      Unlock Wallet
                    </Link>
                    <a
                      href="https://pandoproject.org/assets/Whitepaper%204.0.pdf"
                      className="btn2"
                      target="_blank">
                      Mainnet 4.0
                    </a>

                  </div>


                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="features">
          <div className="container">
            <div className="row align-item-center">
              <div className="col">
                <div className="head">
                  <h2>Advantages at a Glance</h2>
                  <p>Before you choose us, discover some of our benefits</p>
                </div>
              </div>
              <div className="col">
                <div className="h-100">
                  <div className="box f1">

                    <h5>Quick Access</h5>
                    <p>
                      Seamless storage and quick access to your Pando Tokens
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="h-100">
                  <div className="box f2">

                    <h5>Secure</h5>
                    <p>
                      Robust security measures to protect your digital assets
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="h-100">
                  <div className="box f3">

                    <h5>Tracking</h5>
                    <p>
                      Real-time updates and notifications for instant token
                      value tracking
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="statics">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="des">
                  <h2>
                    Embrace the Pando revolution: <br></br>Safeguard, Transact,
                    and Prosper
                  </h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="des">
                  <p>
                    Unveiling the Crucial Elements that Shape Content Importance
                    for Your Success:
                  </p>
                  <ul>
                    <li>
                      Easy-to-use wallet interface for effortless Pando Token
                      management
                    </li>
                    <li>
                      Seamlessly connect with various platforms for smooth
                      transactions and exchanges
                    </li>
                    <li>
                      Unlock opportunities to stake, earn, and expand your Pando
                      Token holdings
                    </li>
                  </ul>
                  <Link to="/create" class="btn1">
                    Create Wallet
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
       
      </div>
    );
  }
}

export default Homepage;
