import React, { useState, useEffect } from "react";
import "./DashboardPage.css";
import ChartComponent from "./ChartComponent";

function DashboardPage() {
  
  return (
    <div>
      <section id="tokens-page" className="dashb">
        <div className="container-fluid">
       
          <div className="row">
            <div className="col-lg-12 ">
              <h4>Price Chart</h4>
             
              <ChartComponent/>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardPage;
