import React, { useState, useEffect } from "react";
import Chart from "chart.js";
import MDSpinner from "react-md-spinner";
import config from '../Config';


const BASE_URL = config.BASE_URL
var isFetchingChart = true
function ChartComponent() {

  let [timeFormat, setFormat] = useState("MMM D");

  let [chartData, setChartData] = useState([]);

  function chartapihit() {
    isFetchingChart = true
    fetch(BASE_URL+"/chart").then((resp) => {
      resp.json().then((res) => {
        setChartData(
          res.quote.map((item) => ({ t: new Date(item.timestamp).toISOString().split('T')[0], y: item.price.toFixed(4) }))
        );
        isFetchingChart = false
      });
    });
  }

  useEffect(() => {
    chartapihit();
  }, []);

  useEffect(() => {
    var ctx = document.getElementById("myChart").getContext("2d");
    var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0.1)');
    gradient.addColorStop(0, 'rgba(80, 184, 223, 1)');
    var labels = []
    var finaldata = []
    for(let i of chartData)
    {
      labels.push(i.t)
      finaldata.push(i.y)
    }
    var myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "PTX/USDT",
            data: finaldata,
            backgroundColor: gradient,
            borderColor: "rgba(80, 184, 223, 1)",
            borderWidth: 1,
            tension: 0.5
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
         
        },
      },
    });
  }, [chartData, timeFormat]);

  return (
    <div>
      {
        isFetchingChart &&
        <MDSpinner singleColor="#ffffff" className="StakesPage__detail-view-spinner" />
      }
     
        
        <div className="chart-img">

          <canvas
            id="myChart"
            width="400"
            height="200"
            aria-label="Hello ARIA World"
            role="img"></canvas>
        </div>
    
    </div>

  );
}

export default ChartComponent;
