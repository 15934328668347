import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from "./state";
import { App } from './App';
import WalletApp from './App';
import { Provider as AlertProvider } from 'react-alert'
import Alerts from './services/Alerts'
import Alert from './components/Alert'
import { hot } from 'react-hot-loader/root'

// optional cofiguration
const options = {
    // you can also just use 'bottom center'
    position: 'top center',
    timeout: 5000,
    offset: '30px',
    transition: 'scale'
};

class AppWrapper extends Component {
    render() {
        let alertRef = Alerts.getRef();

        return (
            <div>
                <BrowserRouter>
                    <Provider store={store}>
                        <AlertProvider ref={alertRef}
                            template={Alert}
                            {...options}>
                            <Switch>
                                <Redirect from='/' to='/unlock' exact={true} />
                                <Route path="/wallet" component={WalletApp} />
                                <Route path="/" component={App} />
                            </Switch>
                        </AlertProvider>
                    </Provider>
                </BrowserRouter>


                <footer id="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="logo-txt">
                                    <img src="/img/logo/Frame 126.svg"></img>

                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col">
                                        <div className="links">
                                            <h5>JOIN</h5>
                                            <ul>
                                                <li>
                                                    <a href="https://pandoproject.org">Rametron</a>
                                                </li>
                                                <li>
                                                    <a href="https://pandoproject.org">Validator</a>
                                                </li>
                                                <li>
                                                    <a href="https://pandoproject.org">Developer</a>
                                                </li>

                                                <li>
                                                    <a href="https://pandoproject.org">Content Creator</a>
                                                </li>

                                                <li>
                                                    <a href="https://pandoproject.org">Advertiser</a>
                                                </li>
                                                <li>
                                                    <a href="https://pandoproject.org">Collaborator</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="links">





                                            <h5>LEARN</h5>
                                            <ul>
                                                <li>
                                                    <a href="https://www.youtube.com/@pandoprojectorg" target="_blank">Guide & Tutorials</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.youtube.com/@pandoprojectorg">Videos</a>
                                                </li>
                                                <li>
                                                    <a href="https://pandoproject.org/assets/Whitepaper%204.0.pdf">Whitepaper 4.0</a>
                                                </li>
                                                <li>
                                                    <a href="https://docs.pandoproject.org/">Pando Doc</a>
                                                </li>
                                                <li>
                                                    <a href="https://github.com/pandoprojects/pando-network-protocol">GitHub</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>



                                    <div className="col">
                                        <div className="links">
                                            <h5>PARTICIPATE</h5>
                                            <ul>
                                                <li>
                                                    <a href="https://pandoproject.org">Community</a>
                                                </li>
                                                <li>
                                                    <a href="https://pandoproject.org">Events</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className="col">
                                        <div className="links">
                                            <h5>COMMUNITY</h5>
                                            <ul>
                                                <li>
                                                    <a href="https://t.me/pandoproject">Telegram</a>
                                                </li>
                                                <li>
                                                    <a href="https://x.com/pandoprojectorg">Twitter</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.youtube.com/@pandoprojectorg">Youtube</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/pandoprojectorg/">Instagram</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.facebook.com/pandoprojectorg">Facebook</a>
                                                </li>
                                                <li>
                                                    <a href="https://pandoproject.org/privacy-policy/">Discord</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.tiktok.com/@pandoprojectorg">TikTok</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className="col">
                                        <div className="links">
                                            <h5>MORE FOR YOU</h5>
                                            <ul>
                                                <li>
                                                    <a href="https://pandoproject.org">Privacy Policy</a>
                                                </li>
                                                <li>
                                                    <a href="https://pandoproject.org">Terms & Conditions</a>
                                                </li>
                                                <li>
                                                    <a href="https://pandoproject.org">Contact Us</a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright">
                                    <p>
                                        © {new Date().getFullYear()} <a href="https://pandoproject.org/" target="_blank">PandoProject</a> | Powered by
                                        <a href="https://pandolab.io/"> Pandolab</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

// export default AppWrapper;
export default process.env.NODE_ENV === "development" ? hot(AppWrapper) : AppWrapper;

