import React from "react";
import { connect } from 'react-redux'
import './StakesPage.css';
import PageHeader from "../components/PageHeader";
import { fetchStakes } from "../state/actions/Stakes";
import { showModal } from "../state/actions/Modals";
import ModalTypes from "../constants/ModalTypes";
import { StakesTable } from "../components/StakesTable";
import MDSpinner from "react-md-spinner";

import { canStakeFromHardwareWallet } from '../Flags';
import Api from "../services/Api";
import Wallet from "../services/Wallet";
import { withTranslation } from "react-i18next";


class StakesPage extends React.Component {

    constructor() {
        super();
        this.state = {
            stackes: [],
            stakeLoading: false
        };
    }
    handleDepositStakeClick = () => {
        const hardware = Wallet.getWalletHardware();

        if (hardware === "ledger" && canStakeFromHardwareWallet() === false) {
            alert("Staking from hardware Ledger Wallet will be supported soon. Stay tuned!");
        }
        else {
            this.props.dispatch(showModal({
                type: ModalTypes.DEPOSIT_STAKE,
            }));
        }
    };

    handleWithdrawStakeClick = () => {
        const hardware = Wallet.getWalletHardware();

        if (hardware === "ledger" && canStakeFromHardwareWallet() === false) {
            alert("Staking from hardware Ledger Wallet will be supported soon. Stay tuned!");
        }
        else {
            this.props.dispatch(showModal({
                type: ModalTypes.WITHDRAW_STAKE,
            }));
        }
    };

    fetchStakes = () => {
        this.props.dispatch(fetchStakes());
    };

    startPollingStakes() {
        this.setState({ stakeLoading: true });
        Api.fetchStakes(Wallet.getWalletAddress()).then((data) => {

            let finalArray = [];
            let rtstake = null;
            let zytastake = null;
            let metastake = null;
            let rtholder = null
            for (let i of data.body.sourceRecords) {
                if (!i.withdrawn) {
                    i.return_height = 'N/A'
                }
                if (i.type == "rametronenterprisep") {
                    rtstake = rtstake + i.amount / 1000000000000000000;
                    rtholder = i.holder
                }
                if (i.type == "vcp") {
                    zytastake = zytastake + i.amount / 1000000000000000000;
                }
                if (i.type == "gcp") {
                    metastake = metastake + i.amount / 1000000000000000000;
                }
                i.amount = Number(i.amount)
                finalArray.push(i)
            }
            const result = Object.values(
                finalArray.reduce((acc, item) => {
                    acc[item.myholder] = acc[item.myholder]
                        ? { ...item, amount: item.amount + acc[item.myholder].amount }
                        : item;
                    return acc;
                }, {})
            );

            Wallet.setStakeType({rtstake:rtstake,zytastake:zytastake,metastake:metastake});
            Wallet.setStakeAddress(rtholder)
            this.setState({ stakeLoading: false });
            this.setState({ stackes: finalArray })
        })
            .catch((err) => {

                this.setState({ stakeLoading: false });
            });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.startPollingStakes();
    }


    componentWillUnmount() {
    }

    render() {
        const { stakes, isFetchingStakes, t } = this.props;

        return (
            <div className="WalletPage">
                <div className="WalletPage__master-view stakepgaez">
                    <div className="bg-mine wrapper-transacton text-center ">
                        <h6 style={{ color: "white" }} sticky={true}>
                            {" "}
                            {t(`STAKES`)}{" "}
                        </h6>
                        <span title="Refresh" onClick={() => this.startPollingStakes()} style={{ cursor: "pointer" }}>
                            <img height="30" src="/img/Path 716.svg" className=""></img>
                        </span>
                    </div>
                    {
                        isFetchingStakes &&
                        <MDSpinner singleColor="#ffffff" className="StakesPage__detail-view-spinner" />
                    }

                    {
                        <div className="table-responsive">
                            <StakesTable t={t} stakes={this.state.stackes} stakeLoading={this.state.stakeLoading} />
                        </div>
                    }

                </div>


            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        stakes: state.stakes.stakes,

        isFetchingStakes: state.stakes.isFetchingStakes
    };
};

export default withTranslation()(connect(mapStateToProps)(StakesPage));
